<div class="container pt-5">
    <h1>{{ title }}</h1>
    <div class="py-2">
        <mat-slide-toggle [(ngModel)]="showCounter" color="warn">counter: {{ showCounter ? 'Yes' : 'No' }}
        </mat-slide-toggle>
    </div>
    <ng-container *ngIf="showCounter">
        <div class="card text-center" style="width: 18rem;">
            <div class="card-body">
                <div class="d-flex justify-content-center pt-4">
                    <button mat-mini-fab (click)="reduce()">
                        <mat-icon>remove</mat-icon>
                    </button>
                    <h3 class="mx-4">{{ counter }}</h3>
                    <button mat-mini-fab (click)="increase()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                <div class="pt-3">
                    <button type="button" class="btn btn-light" (click)="reset()">Reset</button>
                </div>
            </div>
        </div>
    </ng-container>
</div>
